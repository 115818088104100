import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import $ from 'jquery';

import {
  uploadAttachment,
  handleMessage,
  logout,
  fireError,
} from '../../../../../utils';

import Loading from '../../../../shared/Loading';
import styles from './styles.module.scss';
import styles2 from '../styles.module.scss';
import styles3 from '../../styles.module.scss';
import { setPusherMsg, showPusher } from '../../../../../actions';

function Photo() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const [picturesToUpload, setPicturesToUpload] = useState({});
  const [showWebcam, setShowWebcam] = useState(false);
  const [fileName, setFileName] = useState(`mobile_${new Date().getTime()}.png`);
  const [sessionFromReact, setSessionFromReact] = useState('');
  const [description, setDescription] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [cams, setCams] = useState([]);
  const [camIdx, setCamIdx] = useState('');
  const [videoSelect, setVideoSelect] = useState(null);
  const [loading, setLoading] = useState(false);

  const { WOId } = useParams();
  const webcamRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    handleMessage('', 'Photo');
    setDescription('');
    setFileName('');
    const data = new FormData();
    if (showWebcam) {
      const cam = webcamRef.current;
      const img = cam.getScreenshot();
      data.append('DisplayPDF', '1');
      data.append('FileDescription', 'Uploaded from Mobile Portal');
      data.append('camera', img);
    }

    $.each($('input'), (i, ele) => {
      if (ele.files) {
        $.each(ele.files, (j, file) => {
          data.append(`file_${j}`, file);
        });
      } else if (ele.name) {
        data.append(ele.name, ele.value);
      }
    });

    $.each($('textarea'), (i, ele) => {
      if (ele.name) {
        data.append(ele.name, ele.value);
      }
    });
    if (navigator.onLine) {
      uploadAttachment(data)
        .then((response) => {
          // consider all messages as error, the backend could return 200 with an error message
          if (response) {
            throw response;
          }
          handleMessage(`Uploaded successfully, please go back to WO home page and click 'Attachments' (or click the button below) to check. File uploaded for WO# ${WOId}`, 'Photo');
          setShowWebcam(false);
          setPicturesToUpload({});
        })
        .catch((error) => {
          handleMessage(error.statusText, 'Photo');
          if (error.status === 401) {
            dispatch(logout(error));
          }
        });
    } else {
      window.photoFormData = window.photoFormData || [];
      window.photoFormData.push(data);
      fireError('Detected no internet connection. The photo(s) are stored in memory temporarily. When the connection is back they should be uploaded automatically. Once they\'re uploaded you will see a pusher message. Please do not refresh the page until you see the message.');
    }
  };

  const handleCamButtonClick = () => {
    const newCamIdx = (camIdx + 1) % cams.length;
    setCamIdx(newCamIdx);
  };

  const renderCams = () => {
    if (!cams.length) {
      return null;
    }
    const lis = cams.map((c, i) => (
      <li key={`${c.deviceId}-li`}>{`Camera: ${i + 1}`}</li>
    ));
    return (
      <div className={styles.cameraSelector}>
        {`Current selected camera: ${camIdx === '' ? 'none' : camIdx + 1}`}
        <button type="button" className={styles.camToggleBtn} onClick={handleCamButtonClick}>Toggle Camera</button>
        <h3>Cameras detected:</h3>
        <ul className={styles.camList}>
          {lis}
        </ul>
      </div>
    );
  };

  // AFAICT in Safari this only gets default devices until gUM is called :/
  const getDevices = () => (
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        const camsFound = devices.filter((d) => d.kind === 'videoinput');
        if (camsFound.length) {
          setCams(camsFound);
          setCamIdx(0);
        }
      })
  );

  const getStream = () => {
    const constraints = {
      video: true,
    };
    return navigator.mediaDevices.getUserMedia(constraints)
      .catch((e) => console.log(e));
  };

  const handleSelectFromGalary = () => {
    setShowWebcam(false);
    document.getElementById('upload')?.click();
  };

  const handleReconnectFileUpload = async () => {
    const reqAjaxes = [];
    for (let i = 0; i < window.photoFormData.length; i += 1) {
      reqAjaxes.push(uploadAttachment(window.photoFormData[i]));
    }
    await Promise.all(reqAjaxes).then(() => {
      dispatch(showPusher());
      dispatch(setPusherMsg('Connection Restored. Attempted File Upload Requests Have Been Syncronized'));
      window.photoFormData = [];
    }).catch((e) => console.log(e));
  };

  const renderPicturesSelected = () => {
    if (!picturesToUpload.files || Object.values(picturesToUpload.files).length === 0) {
      return null;
    }
    const pictures = Object.values(picturesToUpload.files).map((p, i) => (
      <li key={`${p.name}-${p.size}-li`}>
        {`File ${i + 1}: ${p.name}. Size: ${(p.size / 1024 / 1024).toFixed(2)} mb`}
      </li>
    ));
    return (
      <ul>
        {pictures}
      </ul>
    );
  };

  const renderPicturesSize = () => {
    if (!picturesToUpload.files || Object.values(picturesToUpload.files).length === 0) {
      return null;
    }
    const warningText = 'Some files seem to be invalid. Max size allowed: 10 MB per image, 20MB per submission';
    const pics = Object.values(picturesToUpload.files);
    const len = pics.length;
    let invalid = false;
    let totalSize = 0;
    for (let i = 0; i < len; i += 1) {
      const p = pics[i];
      if (p.size > 10485760) {
        invalid = true;
      }
      totalSize += p.size;
    }
    if (totalSize > 20971520) {
      invalid = true;
    }

    return (
      <div>
        {invalid ? warningText : null}
        <li>{`Total size: ${(totalSize / 1024 / 1024).toFixed(2)} mb`}</li>
      </div>
    );
  };

  useEffect(() => {
    setSessionFromReact(JSON.stringify(currentUser));
    setVideoSelect(document.getElementById('videoSource'));
    window.addEventListener('online', handleReconnectFileUpload);
    return () => window.removeEventListener('online', handleReconnectFileUpload);
  }, []);

  useEffect(() => {
    if (!videoSelect) {
      return;
    }
    setLoading(true);
    getStream().then(getDevices)
      .then(() => { setLoading(false); });
  }, [videoSelect]);

  useEffect(() => {
    if (camIdx === '') {
      return;
    }
    setDeviceId(cams[camIdx].deviceId);
  }, [camIdx]);

  return (
    <form className={styles3.formPaddingTop} onSubmit={onSubmit}>
      <input type="hidden" name="sessionFromReact" value={sessionFromReact} />
      <input type="hidden" name="UTILITYID" value="1" />
      <input type="hidden" name="DisplayPDF" value="1" />
      <input type="hidden" name="WorkOrderID" value={WOId} />
      <input type="hidden" name="action" value="uploadAttachment" />
      <div className={styles.photoPage}>
        <div className={styles3.PaddingBottom}>
          <div className={styles2.title}>Photo</div>
        </div>
        <div className={styles.buttonLine}>
          <button className={styles.buttonSize} type="button" onClick={() => setShowWebcam(true)}>Use camera</button>
        </div>
        {loading ? <Loading /> : null}
        {renderCams()}
        <div className="select hidden">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <select id="videoSource" />
        </div>
        <br />
        <div className={styles.buttonLine}>
          <button className={styles.buttonSize} type="button" onClick={handleSelectFromGalary}>Select from gallery</button>
        </div>
        <br />
        {showWebcam ? (
          <div className={`${styles3.PaddingBottom} ${styles3.displayGrid}`}>
            <label className={styles.displayGrid}>
              <div className={styles.textBoxLabel}>File name:</div>
              <input value={fileName} name="FileName" onChange={(e) => setFileName(e.target.value)} />
            </label>
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={{ deviceId }}
            />
            <button className={styles.buttonCenter} type="button" onClick={onSubmit}>Take Photo</button>
          </div>
        ) : (
          <div>
            <input
              className={styles3.PaddingBottom}
              accept="image/*"
              type="file"
              id="upload"
              name="file"
              onChange={(e) => { setPicturesToUpload(e.target); }}
              value={picturesToUpload.value ?? ''}
              multiple
            />
          </div>
        )}
        <Link to={`/search/${WOId}/attachments`}><button className={styles.button} type="button" onClick={() => {}}>Go to Attachments Page</button></Link>
        <label className={styles.displayFlexTextBox}>
          <div className={styles.textBoxLabel}>File Description:</div>
          <textarea className={styles.textBoxSize} value={description} onChange={(e) => setDescription(e.target.value)} name="FileDescription" />
        </label>
        <br />
        {Object.keys(picturesToUpload).length ? (
          <>
            {renderPicturesSelected()}
            <label htmlFor="uploadPhotoBtn" className={styles.uploadPhoto}>
              {renderPicturesSize()}
              <button type="submit" id="uploadPhotoBtn">Upload photo(s)</button>
            </label>
          </>
        ) : null}
        <div id="msgDivPhoto" />
      </div>
    </form>
  );
}

export default Photo;
